import React, { useState,useEffect } from 'react';
import auth0 from 'auth0-js';
import { ProcessErrors } from '../Utilities/ErrorBox';
import { Loading } from '../Utilities/Loading';
import { APIGet, APIPost, APIRedirect, getEnvironment} from '../API/APIRequest';
import { GetConfiguration } from '../Configuration/ConfigurationManager';
import { GetADToken, SaveADToken, RefreshADToken, GetAuth0TokenLocal, RemoveADToken, RemoveAuth0Token, SaveAuth0Token } from '../Utilities/TokenManager'
import { GetCookieName, GetCookieValue, ValidateCookieValue, DeleteCookie, SaveCookie} from '../Utilities/ADFECookie'
import { GetAuth0UserByEmail, AutologinAPI } from './Auth0/Auth0ADInterface'
import { Auth0EmbeddedAuthenticationProvider } from './Auth0/Auth0EmbeddedAuthenticationProvider'
import { Auth0UniversalAuthenticationProvider } from './Auth0/Auth0UniversalAuthenticationProvider'
import { ADNativeAuthenticationProvider } from './Account/ADNativeAuthenticationProvider'
const AuthContext = React.createContext()

export function useProvideADAuth() {
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [errors, setErrors] = useState([]);
    const [environment, setEnvironment] = useState(getEnvironment());

    const ReturnUrl = () => {
        var params = (new URL(document.location)).searchParams;
        return params.get("ReturnUrl");
    }

    const CheckEmail = (email) => {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('email', email);

            APIPost({
                "controller": "account",
                "action": "checkemail",
                "environment": environment,
                data: bodyFormData,
            }).then((response) => {
                if (response.data.inuse) {
                    resolve("email_found");
                }
                else {
                    resolve("email_not_found");
                }
            }).catch((error) => {
                reject(error);
            });
        });         
    }

    const SendForgottenPasswordLink = (email) => {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('email', email);

            APIPost({
                "controller": "account",
                "action": "forgottenpassword",
                "environment": environment,
                data: bodyFormData,
            }).then((response) => {
                var result = response.data;
            });
        });
    }
}

function useProvideUniversalAuth() {
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [errors, setErrors] = useState([]);
    const [environment, setEnvironment] = useState(getEnvironment());

    const ReturnUrl = () => {
        var params = (new URL(document.location)).searchParams;
        return params.get("ReturnUrl");
    }

    const AuthenticateWithCookie = () => {
        return new Promise((resolve, reject) => {
            GetCookieName().then((cookieName) => {
                var cookieValue = GetCookieValue(cookieName);
                if (cookieValue) {
                    ValidateCookieValue(cookieValue).then((user) => {
                        if (user) {
                            var userToken = {
                                "name": user.userfullname,
                                "token": user.userToken,
                                "partyid": user.partyid,
                                "username": user.username
                            };
                            SaveADToken(userToken);
                            resolve(userToken);
                        }
                        resolve(null);
                    });
                }
                resolve(null);
            });

        });

    }

    const AuthenticateWithAuth0 = () => {
        return new Promise((resolve, reject) => {
            var auth0Token = GetAuth0TokenLocal();
            if (auth0Token) {
                //var auth0Id = auth0Token.auth0id;
                //GetAuth0TokenFromServer(auth0Id).then(() => {
                //    CreateADAuthenticationAssets(auth0Id).then((userToken) => {
                //        sessionStorage.setItem('authenticatedWithAuth0', 'true');
                //        resolve(userToken);
                //    }).catch((errors) => {
                //        reject(errors);
                //    });
                //}).catch((errors) => {
                //    reject(errors);
                //});

            }
            else {
                resolve(null);
            }
        });
    }

   const getUserToken = () => {
        return new Promise((resolve, reject) => {
            var userToken = GetADToken();
            if (isLoading) {
                GetConfiguration("userconfiguration").then((configuration) => {
                    var checkADFECookie = true;
                    if (configuration && configuration.authenticationprovidertype == "auth0") {
                        var authenticatedWithAuth0 = sessionStorage.getItem('authenticatedWithAuth0');
                        checkADFECookie = (authenticatedWithAuth0 && Boolean(authenticatedWithAuth0));
                    }
                    if (userToken?.token) {  
                        resolve(userToken);   
                    }
                    if (!userToken?.token && checkADFECookie) {
                        AuthenticateWithCookie().then((userToken) => {
                            if (userToken?.token) {
                                resolve(userToken);
                            }
                        }).catch((errors) =>
                        {
                            reject(errors);
                        });
                    }
                    if (!userToken?.token) {
                        if (configuration && configuration.authenticationprovidertype == "auth0") {
                            AuthenticateWithAuth0().then((userToken) => {
                                resolve(userToken);
                            }).catch((errors) => {
                                reject(errors);
                            });
                        }
                        else {
                            resolve(null);
                        }
                    }
                    else {
                        resolve(userToken);
                    }
                });
            }
            else {
                resolve(userToken);
            }
        });

    }

    const GetSocialConnections = () => {
        return new Promise((resolve, reject) => {            
            APIGet({
                "controller": "auth0",
                "action": "getsocialconnections",
                "environment": environment
            }).
                then((response) => {
                    var result = response.data;

                    if (result.success) {
                        resolve(result.connections);
                    }
                    else {
                        reject(result.errors);
                    }
                }).
                catch((error) => {
                    var unexpected = []
                    unexpected.push(error)
                    reject(error);
                });           
        });
        
    }

    const CheckEmail = (email) => {
        GetConfiguration("userconfiguration").then((configuration) => {
            if (configuration && configuration.authenticationprovidertype == "auth0") {
                GetAuth0UserByEmail(email).then((auth0response) => {
                    if (auth0response.status == 0) {
                        var returnUrl = ReturnUrl();                       
                        var data = {
                            "ReturnUrl": returnUrl,
                            "email" : email
                        } 
                        APIRedirect({
                            "controller": "auth0",
                            "action": "authenticate",
                            "environment": environment,
                            data: data
                        });
                    }
                    else {
                        var returnUrl = ReturnUrl();
                        window.location.href = "/register?ReturnUrl=" + returnUrl;
                    }
                }).catch((errors) => {
                    setErrors(errors);
                });
            }
        });
    }

    function Logout(returnUrl) {
        GetCookieName().then((cookieName) => {
            DeleteCookie(cookieName);
        });

        var userToken = GetADToken();
        if (userToken) {
            RemoveADToken();
        }
        if (!returnUrl) {
            returnUrl = window.location.origin;
        }
        GetConfiguration("userconfiguration").then((configuration) => {
            var providerType = configuration.authenticationprovidertype;
            if (providerType == "auth0") {
                RemoveAuth0Token();
                var data = {
                    "ReturnUrl": returnUrl
                }
                APIRedirect({
                    "controller": "auth0",
                    "action": "logout",
                    "environment": environment,
                    data: data
                });
            }
        });
    }

    if (isLoading) {
        getUserToken().then((userToken) => {
            setIsLoading(false);
            setUserData(userToken);

        }).catch((error) => {
            errors.push(error);
            setIsLoading(false);
        });
    }
    else {
        var sessionToken = GetADToken()
        if (!userData && sessionToken) {
            setUserData(sessionToken);
        }
    }
    return {
        userData,
        isLoading,
        errors,
        ReturnUrl,
        CheckEmail,
        Logout,
        GetSocialConnections,
    }
}




export function useProvideEmbeddedAuth() {
   
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [errors, setErrors] = useState([]);
    const [webAuth, setWebAuth] = useState(null)
    const [environment, setEnvironment] = useState(getEnvironment());
    const [currentAction, setCurrentAction] = useState([]);
    const [returnUrl, setReturnUrl] = useState(null);
  


    const params = new Proxy(new URLSearchParams(window.location.search),
        {
            get: (searchParams, props) => searchParams.get(props),
        });

    const getWebAuth = () => {
        if (!webAuth) {
            GetConfiguration("auth0authenticationapiconfiguration", environment).then((configuration) => {
                var wa = new auth0.WebAuth(
                    {
                        domain: configuration.customDomain,
                        clientID: configuration.clientId,
                        responseMode: 'query',
                        responseType: 'code',
                        scope: 'openid profile email offline_access'
                    });
                setWebAuth(wa);
            }).catch((webauthErrors) => {
                errors.push(webauthErrors);
            });
        }
    }

    
    const ReturnUrl = () => {
        
        let params = (new URL(document.location)).searchParams;
        let retUrl = params.get("ReturnUrl");
        if (retUrl) {
            ///////
            const _fullurl = document.location.href;
            const _returnUrlIndex = _fullurl.indexOf('ReturnUrl');
            let _returl = _fullurl.slice(_returnUrlIndex + 10); // Get the full ReturnUrl part from the URL

            ////////
            setReturnUrl(_returl);
            return _returl;
            } 

        if (!returnUrl ) {
              //return new Promise((resolve, reject) => {
        let params = (new URL(document.location)).searchParams;
        let retUrl = params.get("ReturnUrl");
           
            if (!retUrl) {
                retUrl = document.referrer;
            }
            if (!retUrl) {
                retUrl = sessionStorage.getItem("ReturnUrl");
            }
            if (retUrl) {
                setReturnUrl(retUrl);
                //resolve(returnUrl);
            }
            if (!retUrl) {
                APIGet({
                    "controller": "brand",
                    "action": "getcurrentbrand",
                    "environment": environment
                }).then((response) => {

                    if (response?.data?.brand?.returnurl) { 
                        sessionStorage.setItem("ReturnUrl", response.data.brand.returnurl);
                        setReturnUrl(response.data.brand.returnurl);
                    }
                    //resolve(returnUrl);
                }).
                catch((error) => {
                    //reject(error);
                })
            }
        }
        return returnUrl;
        //})
    }

    const ClearUserData = () => {
        RemoveADToken();
        setUserData(null);
    }

    const AuthenticateWithCookie = () => {
        return new Promise((resolve, reject) => {
            GetCookieName().then((cookieName) => {
                var cookieValue = GetCookieValue(cookieName);
                if (cookieValue) {
                    ValidateCookieValue(cookieValue).then((user) => {
                        if (user) {
                            var userToken = {
                                "name": user.userfullname,
                                "token": user.userToken,
                                "partyid": user.partyid,
                                "username": user.username
                            };
                            SaveADToken(userToken);
                            resolve(userToken);
                        }
                        resolve(null);
                    });
                }
                resolve(null);
            });

        });

    }

    const Login = (username, password) => {
        return new Promise((resolve, reject) => {           
            webAuth.client.login({
                username: username,
                password: password,
                realm: 'Username-Password-Authentication'
            }, async (error, result) => {
                if (error) {
                    reject(error);
                }
                else {                   
                    
                    var data = {
                        access_token: result.accessToken,
                        refresh_token: result.refreshToken,
                        id_token: result.idToken,
                        scope: '',
                        expires_in: result.expiresIn,
                        token_type: 'access_token'
                    };
                    
                    APIPost({
                        "controller": "auth0",
                        "action": "processtokensfromembeddedlogin",
                        "environment": environment,
                        "content_type": "json",
                        data: data       
                    }).then((axresponse) => {
                        var axresult = axresponse.data;
                        if (!axresult.success) {
                            reject(axresult.errors);
                        }
                        var user = axresult.user;
                        if (user) {
                            var userToken = {
                                "name": user.userfullname,
                                "token": user.userToken,
                                "partyid": user.partyid,
                                "username": user.username
                            };
                            SaveAuth0Token(axresult.ad_ref_token);
                            SaveADToken(userToken);
                            setUserData(userToken);
                            resolve(axresult);
                        }                       
                    }).catch((axerror) => {
                        reject(axerror);
                    });
                }
            })
        });
    }

    const ClearAuth0Tokens = (refToken) => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("refToken", refToken);
            APIPost({
                "controller": "auth0",
                "action": "cleartokens",
                "environment": environment,
                data: formData
            }).then((axresult) => {
                resolve(axresult.data.success);
            }).catch((axerror) => {
                reject(axerror);
            });
        });
    }

    const AutoLogin = (refParam) => {
        return new Promise((resolve, reject) => {
            if (currentAction.length == 0) {
                currentAction.push("authenticating");
                var auth0Token = refParam;
                    if (!auth0Token) {
                    auth0Token = GetAuth0TokenLocal();
                }
                if (auth0Token) {
                    AutologinAPI(environment, auth0Token).then((userToken) => {
                        sessionStorage.setItem('authenticatedWithAuth0', 'true');
                        currentAction.pop();
                        resolve(userToken);
                    }).catch((aerrors) => {
                        currentAction.pop();
                        reject(aerrors);
                    });

                }
                else {
                    currentAction.pop();
                    resolve(null);
                }
            }
        });
    }
    
    const getUserToken = () => {
        return new Promise((resolve, reject) => {
            var refToken = params.refToken;
            var userToken = GetADToken();   
            if (isLoading) {
                GetConfiguration("userconfiguration", environment).then((configuration) => {
                    //var checkADFECookie = true;
                    //if (configuration && configuration.authenticationprovidertype == "auth0") {
                    //    var authenticatedWithAuth0 = sessionStorage.getItem('authenticatedWithAuth0');
                    //    checkADFECookie = (authenticatedWithAuth0 && Boolean(authenticatedWithAuth0));
                    //}
                    //if (userToken?.token) {
                    //    RefreshADToken().then((result) => {
                    //        userToken = result;
                    //        resolve(userToken);
                    //    });
                    //}
                    //if (!userToken?.token && checkADFECookie) {
                    //    AuthenticateWithCookie().then((userToken) => {
                    //        if (userToken?.token) {
                    //            resolve(userToken);
                    //        }
                    //    }).catch((aerrors) => {
                    //        reject(aerrors);
                    //    });
                    //}
                    if (!userToken?.token || refToken) {
                        if (configuration && configuration.authenticationprovidertype == "auth0") {
                            AutoLogin(refToken).then((userToken) => {
                                resolve(userToken);
                            }).catch((aerrors) => {
                                reject(aerrors);
                            });
                        }
                        else {
                            resolve(null);
                        }
                    }
                    else {
                        resolve(userToken);
                    }
                }).catch((cerrors) => {
                    reject(cerrors);
                })
            }
            else {
                resolve(userToken);
            }
        });

    }

    const GetADRefToken = () => {
        var refToken = params.refToken;
        if (!refToken) {
            refToken = GetAuth0TokenLocal();
        }
        return refToken;
    }
  
    const GetSocialConnections = () => {
        return new Promise((resolve, reject) => {
            APIGet({
                "controller": "auth0",
                "action": "getsocialconnections",
                "environment": environment
            }).
                then((response) => {
                    var result = response.data;

                    if (result.success) {
                        resolve(result.connections);
                    }
                    else {
                        reject(result.errors);
                    }
                }).
                catch((error) => {
                    var unexpected = []
                    unexpected.push(error)
                    reject(error);
                });
        });

    }

    const CheckEmail = (email) => {
        return new Promise((resolve, reject) => {
            GetConfiguration("userconfiguration", environment).then((configuration) => {
                if (configuration && configuration.authenticationprovidertype == "auth0") {
                    GetAuth0UserByEmail(email, environment).then((auth0response) => {
                        if (auth0response.status == 0) {
                            resolve({ status: "email_found" });                          
                        }
                        else {
                            resolve({ status: "email_not_found" });
                        }
                    }).catch((errors) => {
                        //setErrors(errors);
                        reject(errors)
                    });
                }
            });
        });       
    }

    function Logout(returnUrl, refToken) {
        GetCookieName().then((cookieName) => {
            DeleteCookie(cookieName);
        });

        if (!refToken) {
            refToken = GetAuth0TokenLocal();
        }
        if (refToken) {
            RemoveADToken();
            ClearAuth0Tokens(refToken).then((result) => {
                RemoveAuth0Token();
            }).catch((error) => {
                setErrors(ProcessErrors(error));
            });
        }
        
        if (!returnUrl) {
            returnUrl = window.location.origin;
        }
       
        var callBack = window.location.href;
        if (returnUrl) {
            callBack += "?ReturnUrl=" + returnUrl;
        }
        if (callBack.indexOf("?") > 0) {
            callBack += "&IsCallback=true";
        }
        else {
            callBack += "?IsCallback=true";
        }
        webAuth.logout({ returnTo: callBack });                       
    }

    const SendForgottenPasswordLink = (email) => {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('email', email);

            APIPost({
                "controller": "auth0",
                "action": "sendforgottenpasswordlink",
                "environment": environment,
                data: bodyFormData,
            }).then((response) => {
                var result = response.data;
                if (!result.success) {
                    errors.push(ProcessErrors(result.errors));
                    reject(result.errors);
                }
                else {
                    resolve(result);
                }
            }).catch((axiosErrors) => {

                errors.push((ProcessErrors("error.unexpected")));
            });
        });
    }

    const ValidatePasswordResetToken = (token) => {
        return new Promise((resolve, reject) => {
            APIPost({
                "controller": "auth0",
                "action": "validateresetpasswordtoken",
                "environment": environment,
                "identifier": token
            }).then((response) => {
                var result = response.data;
                if (!result.success) {
                    if (errors.length == 0) {
                        result.errors.forEach((error) => {
                            errors.push(error);
                        });
                    }
                    reject(result);
                }
                else {
                    resolve(result);
                }
            }).catch((axiosErrors) => {
                errors.push("error.unexpected");
            });
        });
    }

    const ResetPassword = (token, newPassword) => {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('newPassword', newPassword);
          
            APIPost({
                "controller": "auth0",
                "action": "resetpassword",
                "environment": environment,
                "identifier": token,
                data: bodyFormData
            }).then((response) => {
                var result = response.data;
                if (!result.success) {
                    errors.push(ProcessErrors(result.errors));
                    reject(result);
                }
                else {
                    resolve(result);
                }
            }).catch((errors) => {
                errors.push((ProcessErrors("error.unexpected")));
            });
        });
    }


    //if (!webAuth) {
        getWebAuth();
    //}

    if (isLoading) {
        getUserToken().then((userToken) => {
            setIsLoading(false);
            setUserData(userToken);

        }).catch((error) => {
            errors.push(ProcessErrors("error.unexpected"));
            setIsLoading(false);
        });
    }
    else {
        var sessionToken = GetADToken()
        if (!userData && sessionToken) {
            setUserData(sessionToken);
        }
    }
   
    return {
        environment,
        userData,
        isLoading,
        setErrors,
        errors,
        ReturnUrl,
        CheckEmail,
        Login,
        Logout,
        GetADRefToken,
        GetSocialConnections,
        ClearUserData,
        SendForgottenPasswordLink,
        ValidatePasswordResetToken,
        ResetPassword
    }
}

function AuthenticationProvider(props) {

    const [configuration, setConfiguration] = useState(null);
    const GetAuthConfiguration = () => {
        let environment = getEnvironment();
        GetConfiguration("userconfiguration", environment).then((response) => {
            console.log(response);
            setConfiguration(response);
        });
    }

    if (!configuration) {
        GetAuthConfiguration();
    }
    let authProviderType = configuration?.authenticationprovidertype;
    
console.log(authProviderType);
    if (authProviderType == "adnative")
        return (<ADNativeAuthenticationProvider {...props} AuthContext={AuthContext} />);
    if (authProviderType == "auth0embedded")
        return (<Auth0EmbeddedAuthenticationProvider {...props} AuthContext={AuthContext} />);
    if (authProviderType == "auth0universal")
        return (<Auth0UniversalAuthenticationProvider {...props} AuthContext={AuthContext} />);
    else
        return (<Loading loading={true} class={"center-column"} />);
   
}


const useAuth = () => {
    return React.useContext(AuthContext);
}
export {AuthenticationProvider, useAuth}