import { useEffect } from 'react';
import { useAuth } from '../components/Authentication/AuthenticationProvider';


export default function useCreateUrl() {

    const auth= useAuth();
    const env= auth.environment;
    const returnUrl = auth.ReturnUrl() || sessionStorage.getItem("ReturnUrl");
    const createUrl = (destination) => {
  
        let url = "/"
        if (env) {
            url += env + "/";
        }
        if(destination){
            url += destination+"?ReturnUrl=" + returnUrl;
            return url
        }
        
    }
    return createUrl
}
